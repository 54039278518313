// Map the emotion to the colors
const generator = () => ({
  "admiration": "#FFD700", // Gold
  "amusement": "#FF69B4", // Hot Pink
  "anger": "#FF0000", // Red
  "annoyance": "#CD5C5C", // Indian Red
  "approval": "#32CD32", // Lime Green
  "caring": "#FFB6C1", // Light Pink
  "confusion": "#D3D3D3", // Light Gray
  "curiosity": "#1E90FF", // Dodger Blue
  "desire": "#FF4500", // Orange Red
  "disappointment": "#778899", // Light Slate Gray
  "disapproval": "#696969", // Dim Gray
  "disgust": "#8B0000", // Dark Red
  "embarrassment": "#FFC0CB", // Pink
  "excitement": "#FFFF33", // Bright Yellow
  "fear": "#800080", // Purple
  "gratitude": "#FFDEAD", // Navajo White
  "grief": "#2F4F4F", // Dark Slate Gray
  "joy": "#FFFF00", // Yellow
  "love": "#DB7093", // Pale Violet Red
  "nervousness": "#FFA500", // Orange
  "optimism": "#00FF00", // Lime
  "pride": "#8A2BE2", // Blue Violet
  "realization": "#40E0D0", // Turquoise
  "relief": "#ADD8E6", // Light Blue
  "remorse": "#A9A9A9", // Dark Gray
  "sadness": "#0000CD", // Medium Blue
  "surprise": "#FFA07A", // Light Salmon
  "neutral": "#808080" // Gray
})

export default generator()
