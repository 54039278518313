// Map the emotion to the emoji
const generator = () => ({
  "admiration": "👏",
  "amusement": "😄",
  "anger": "😡",
  "annoyance": "😒",
  "approval": "👍",
  "caring": "💓",
  "confusion": "😕",
  "curiosity": "🤔",
  "desire": "❤️",
  "disappointment": "😞",
  "disapproval": "👎",
  "disgust": "🤢",
  "embarrassment": "😳",
  "excitement": "🤩",
  "fear": "😨",
  "gratitude": "🙏",
  "grief": "😢",
  "joy": "😂",
  "love": "😍",
  "nervousness": "😬",
  "optimism": "🌟",
  "pride": "😌",
  "realization": "💡",
  "relief": "😌",
  "remorse": "😔",
  "sadness": "😭",
  "surprise": "😮",
  "neutral": "😐"
})

export default generator()
